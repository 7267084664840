/* Provide sufficient contrast against white background */

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body, #root {
  height: 100%;
}
body{
    padding-bottom:57px;
}
.top-header{
  margin: 0px;
}
/* form css */
.formControl {
  width: 100%;
  background-color: #eee;
  color: #413F3F !important;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  padding: 13px 16px;
  line-height: 1.4;
  box-sizing: border-box;
  border:none;
}
.formControl label {
  color: #413F3F !important;
}
.formControl .MuiOutlinedInput-notchedOutline {
  border: none
}
.formControl .MuiInputBase-root::before, .formControl .MuiInputBase-root::after {
  display: none !important;
}
.formControl::placeholder {
  color: #413F3F;
}
.insightselect {
  width: 100%;
  border-radius: 10px !important;
  height: 45px;
  color: #413F3F !important;
}
/* form css end */

body {
  background-image: url('/images/MP-Background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    background-attachment: fixed;
    background-position: center;
    margin: 0px
}
.errormessage{
    color: red;
}

.successmessage{
    color:green;
}
.customefieldhe {
    display: flex;
    justify-content: space-between; 
    padding: 5px 10px 5px 20px;
    align-items: center; 
    background: #413F3F;
    margin-top:24px;
}
.customefieldhe button::before{
    color:#fff;
}
.rangevalue {
    display: flex;
    justify-content: space-around;
    margin: 10px 0 20px 0;
    align-items: center;
    font-size: 14px;
}
.rangefrom {
    width: 60px;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    margin-right: 5px; 
    padding: 0 0 5px 0;
    font-size:14px;
}
.rangeto{
    margin-left:5px;
}
.alrt {
    position: fixed;
    bottom:60px; 
    left: 30%; 
    right: 30%;
}
.alrt .MuiAlert-message {
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%; 
    align-items: center;
    }
    .alrt .MuiAlert-message button{
        margin-left:10px;
    }
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.css-1bx5ylf{
    padding-bottom:57px;
}

.cptable .property-page-table {
    overflow-x: scroll;
}

.cptable .property-page-table::-webkit-scrollbar {
     height: 5px;
  }

    .cptable .property-page-table::-webkit-scrollbar-track {
        background-color: none;
    }

    .cptable .property-page-table::-webkit-scrollbar-thumb {
        background-color: #2D70DA;
    }
    .cptable .property-page-table tr:last-child  td{
       border:none;
    }
    .portfoliotableCompany tr th:first-child, .portfoliotableCompany tr td:first-child {
        width: 30px;
        padding: 12px;
    }
.portfoliotableCompany tr th:nth-child(2), .portfoliotableCompany tr td:nth-child(2) {
    width: 35%;
    padding-left:0;
}
    @import url('./styles/menu.css');
    @import url('./styles/search-results.css');
    @import url('./styles/loading-spinner.css');
    @import url('./styles/profile.css');
    @import url('./styles/registration.css');
    @import url('./styles/wall.css');


  
  

   
