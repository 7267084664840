.registration-wrapper {
    min-height: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
}
.registration-wrapper form {
    margin-top: 20px;
}
.registration-wrapper form .captcha {
    margin-top: 20px;
}
.registration-wrapper form .captcha > div{
    margin: auto;
}