.results-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
    flex-wrap: wrap;
}
.resLayout-root {
    padding: 0 !important;
    margin-top: -10px;
    width: fit-content !important;
}
.resLayout-item {
    padding-top: 10px !important;
    margin-right: 5px !important;
}
.resLayout-item.item-container {
    padding: 0px !important;
    margin: -10px !important;
    display: flex;
}
.resLayout-item:first-of-type {
    margin-left: 0 !important;
}
@media (min-width: 900px) {
    .contained-md {
        max-width: fit-content !important;
    }
    .results-actions {
        align-items: flex-end;
        flex-direction: row;
    }
}

.results-actions .filters-selected {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
}

.listings-results .pagination {
    display: flex;
    width: 210px;
    margin: auto;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 50px;
}

.search-filters {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 40px;
    justify-content: space-between;
    /* margin-top: 86px; */
 
}
/* .search-filter{
    margin-top: 86px;
} */

.MuiAutocomplete-root .MuiChip-deleteIcon {
    width: 23px;
}

.filters-actions {
    display: flex;
    justify-content: flex-end;
}

#dynamic-background {
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain; 
    overflow: auto;
    background-attachment: fixed;
    transition: all ease-in-out .1s;
    display: flex;
    flex-direction: column;
}

#dynamic-background .container {
    flex-grow: 1;
}

.pagination-wrapper li:first-of-type button {
    border-radius: 3px 0 0 3px;
}
.pagination-wrapper li:last-of-type button {
    border-radius: 0 3px 3px 0;
}
.sort-btns {
    display: inline-flex;
    flex-direction: column;
    margin-left: 5px;
}
@media (max-width: 767px) {
    .search-filters > div {
        width: 100%;
    }
}

.contact-icons {
    display: flex;
    align-items: center;
}
.contact-icons a:nth-child(2) {
    margin-left: 6px;
}