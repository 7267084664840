.mih-nav nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1090px) {
    .nav-menu-desktop {
        display: none !important;
    }
    .nav-menu-mobile {
        display: block !important;
    }
}