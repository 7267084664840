.profile .profile-actions {
    display: flex;
}
.profile .MuiCard-root {
    margin-top: 24px;
    margin-bottom: 0;
}
.company-profile .stock-tables {
    display: flex;
    margin-top: 12px;
}
.company-profile .stock-tables .MuiTableContainer-root:last-of-type {
    margin-left: 6px;
}
.print-logo {
    display: none;
}

@media print {
    body, html, #root {
        width: 100%;
    }
    #dynamic-background {
        overflow: initial;
    }
    #dynamic-background .container {
        padding-top: 0;
    }
    * {
        font-family: 'Roboto', sans-serif !important;
        color: black !important;
    }
    .print-logo {
        display: block;
        height: 30px;
    }
    .print-logo img {
        height: 100%;
    }
    header,
    .mih-nav,
    footer,
    button.back-btn {
        display: none;
    }
    .print a {
        text-decoration: none;
    }
    .print .profile-actions {
        display: none !important;
    }
    .graph-actions .MuiFormGroup-root .MuiRadio-root,
    .graph-actions .MuiFormGroup-root .MuiRadio-root+.MuiFormControlLabel-label {
        display: none;
    }
    .graph-actions .MuiFormGroup-root .MuiRadio-root.Mui-checked {
        background-color: transparent;
        display: block;
        border: none;
    }
    .graph-actions .MuiFormGroup-root .MuiRadio-root.Mui-checked+.MuiFormControlLabel-label {
        display: block;
        color: black;
    }
    .MuiSelect-icon {
        display: none !important;
    }
    body, #dynamic-background {
        background-image: none !important;
    }
    .rv-xy-plot .rv-xy-plot__inner {
        transform: translate(-150px, 0px) scale(0.8);
    }
    .MuiCard-root.title .MuiCardContent-root {
        padding: 0 24px !important;
    }
}