.wall-logos, .wall-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}
.wall-features .feature {
    display: flex;
}
.wall-features .ft-2 {
    margin: 0 24px;
}
.wall-features .feature p {
    font-size: 1.25rem;
    margin-left: 4px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
}
.wall-actions {
    margin: 30px 0;
}
.wall-actions .MuiLink-root {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 400px;
}
.wall-actions .contained {
    background-color: #2d70da;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 10px 17px;
    font-weight: 500;
    box-shadow: #0000003d 0px 2px 2px;
    font-family: 'Roboto';
    border-radius: 3px;
}
.wall-actions .text {
    background-color: #fff;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 10px 17px;
    border: 1px solid #212529;
    color: #212529;
    font-weight: 500;
    font-family: 'Roboto';
    border-radius: 3px;
}
.wall-actions .MuiIcon-root {
    font-size: 1rem;
}
.sec-action {
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    color: #918F8F !important;
    text-align: center;
}
@media (max-width:991px) {
    .MuiGrid-root.wall-features {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .wall-logos, .wall-actions {
        flex-direction: column;
    }
    .wall-logos p {
        margin: 10px 0;
    }
    .wall-features .feature {
        align-items: center;
        justify-content: center;
    }
    .wall-features .ft-2 {
        margin: 10px 0;
    }
    .wall-actions p {
        margin: 10px 0;
    }
}
@media (max-width:599px) {
    .title {
        font-size: 1.5rem;
    }
    .wall-features .feature p {
        font-size: 1rem;
    }
    .wall-logos img {
        height: 30px;
    }
    .wall-actions .MuiIcon-root {
        display: none;
    }
    .wall-actions .MuiLink-root {
        justify-content: center;
    }
}