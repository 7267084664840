@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 41px !important;
    height: 41px !important;
    font-size: 40px !important;
    padding-top: 2px;
    animation: spinner 1.5s linear infinite;
}
.loading-spinner::before {
    color: #2D70DA;
    margin-left: -2px;
}
.loading-spinner::after {
    margin-left: -2px;
}
.loading-spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
}